import React from 'react';
import { Navbar, Form, Nav, Container } from 'react-bootstrap';
import { NavDropdown } from 'react-bootstrap';
import { FaUser, FaShoppingCart, FaBars, FaSearch } from 'react-icons/fa';
import Logo from "../assets/images/sawbladcom-logo.png";
import { useNavigate } from 'react-router-dom';

const Header: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/navigation');
  };

  return (
    <div className='sticky-header'>
      <Navbar className="custom-navbar2" expand="lg">
        <div className="logo-container">
          <Navbar.Brand href="#" className="custom-navbar-brand">
            <img src={Logo} alt="Logo" className="custom-logo" />
          </Navbar.Brand>
        </div>
        <div className="d-lg-none d-flex align-items-center mobile-icons">
          <Nav.Link href="#" className="text-light muser-icon">
            <FaUser />
          </Nav.Link>
          <Nav.Link href="#" className="text-light mucart-icon">
            <FaShoppingCart />
          </Nav.Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0 mbar-icon" onClick={handleNavigation}>
            <FaBars />
          </Navbar.Toggle>
        </div>
        <Navbar.Collapse id="basic-navbar-nav" className="d-lg-flex justify-content-between nav-right-items">
          <Form className="d-flex custom-search-form ms-auto d-none d-lg-flex" role="search">
            <div className="custom-search-input-wrapper">
              <input
                className="form-control custom-search-input"
                type="search"
                placeholder="Product Search - (Name/SKU)"
                aria-label="Search"
              />
              <FaSearch className="custom-search-icon" />
            </div>
          </Form>
          <Nav className="ms-auto d-none d-lg-flex align-items-center">
            <Nav.Link href="#" className="d-flex align-items-center text-light custom-nav-link">
              <FaUser className="me-2" /> LOGIN
            </Nav.Link>
            <Nav.Link href="#" className="d-flex align-items-center text-light custom-nav-link">
              <FaShoppingCart className="me-2" /> CART
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Navbar expand="lg" className="custom-navbar bg-light justify-content-center d-none d-lg-flex">
        <Container fluid className="d-flex justify-content-center">
          <Navbar.Collapse id="navbar-nav">
            <Nav className="mx-auto">
              <NavDropdown title="BAND SAW BLADES" id="bandsawblades-dropdown" className="nav-item bandsawblades-dropdown">
                <NavDropdown.Item key="metal-cutting" href="#">Metal Cutting</NavDropdown.Item>
                <NavDropdown.Item key="wood-cutting" href="#">Wood Cutting</NavDropdown.Item>
                <NavDropdown.Item key="others" href="#">Others</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="BAND SAWS" id="bandsaws-dropdown" className="nav-item bandsawblades-dropdown">
                <NavDropdown.Item key="horizontal" href="#">Horizontal</NavDropdown.Item>
                <NavDropdown.Item key="vertical" href="#">Vertical</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="PARTS & UPGRADES" id="partsupgrades-dropdown" className="nav-item bandsawblades-dropdown">
                <NavDropdown.Item key="blades" href="#">Blades</NavDropdown.Item>
                <NavDropdown.Item key="parts" href="#">Parts</NavDropdown.Item>
                <NavDropdown.Item key="accessories" href="#">Accessories</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="SAWING RESOURCES" id="sawingresources-dropdown" className="nav-item bandsawblades-dropdown">
                <NavDropdown.Item key="guides" href="#">Guides</NavDropdown.Item>
                <NavDropdown.Item key="tips" href="#">Tips</NavDropdown.Item>
                <NavDropdown.Item key="videos" href="#">Videos</NavDropdown.Item>
              </NavDropdown>
              <Nav.Item className="nav-item" key="contact-us">
                <Nav.Link className="contact-us active" href="#">CONTACT US</Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
