import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import Select, { GroupBase, SingleValue, StylesConfig } from 'react-select';

type OptionType = {
    value: string;
    label: string;
};

const SelectSize4: React.FC = () => {
    const feetOptions: OptionType[] = [
        { value: '', label: 'Select Feet' },
        { value: '1', label: '1 ft' },
        { value: '2', label: '2 ft' },
        { value: '3', label: '3 ft' },
    ];

    const inchesOptions: OptionType[] = [
        { value: '', label: 'Select Inches' },
        { value: '1', label: '1 in' },
        { value: '2', label: '2 in' },
        { value: '3', label: '3 in' },
    ];

    const fractionOptions: OptionType[] = [
        { value: '', label: 'Fraction/In' },
        { value: '1/4', label: '1/4' },
        { value: '1/2', label: '1/2' },
        { value: '3/4', label: '3/4' },
    ];

    const groupedOptions: GroupBase<OptionType>[] = [
        {
            label: '1/4" (.035)',
            options: [
                { value: '1/4-1.1-1.4', label: '1.1-1.4' },
                { value: '1/4-1.4-2.0', label: '1.4-2.0' },
            ],
        },
        {
            label: '3/8" (.025)',
            options: [
                { value: '3/8-1.1-1.4', label: '1.1-1.4' },
                { value: '3/8-1.4-2.0', label: '1.4-2.0' },
            ],
        },
        {
            label: '3/8" (.035)',
            options: [
                { value: '3/8-1.1-1.4', label: '1.1-1.4' },
                { value: '3/8-1.4-2.0', label: '1.4-2.0' },
            ],
        },
        {
            label: '1/2" (.025)',
            options: [
                { value: '1/2-1.1-1.4', label: '1.1-1.4' },
                { value: '1/2-1.4-2.0', label: '1.4-2.0' },
            ],
        },
        {
            label: '1/2" (.035)',
            options: [
                { value: '1/2-1.1-1.4', label: '1.1-1.4' },
                { value: '1/2-1.4-2.0', label: '1.4-2.0' },
            ],
        },
    ];

    const customStyles: StylesConfig<OptionType, false> = {
        groupHeading: (base) => ({
            ...base,
            fontWeight: 'bold',  // Make the label bold
            fontSize: '16px',    // Set the font size
        }),
    };

    const handleBladeWidthAndToothPitchChange = (
        selectedOption: SingleValue<OptionType | GroupBase<OptionType>>
    ) => {
        if (selectedOption && 'value' in selectedOption) {
            console.log(selectedOption.value);  // Replace with your logic
        }
    };

    const handleFeetChange = (selectedOption: SingleValue<OptionType>) => {
        console.log(selectedOption ? selectedOption.value : '');  // Replace with your logic
    };

    const handleInchesChange = (selectedOption: SingleValue<OptionType>) => {
        console.log(selectedOption ? selectedOption.value : '');  // Replace with your logic
    };

    const handleFractionChange = (selectedOption: SingleValue<OptionType>) => {
        console.log(selectedOption ? selectedOption.value : '');  // Replace with your logic
    };

    return (
        <div className="select-size select-size-2">
            <div className='cart-card'>
                <div className='cart-card-body'>
                    <p className="fs-15 fw-semibold mb-3 small-title2">Select Blade</p>
                    <Form>
                        <Row>
                            <Col xs={12} md={12} className="mb-2">
                                <Select
                                    name="bladeWidthAndToothPitch"
                                    options={groupedOptions}
                                    className="basic-multi-select flex-grow-1"
                                    menuPlacement="auto"
                                    classNamePrefix="Select2"
                                    styles={customStyles}  // Apply custom styles
                                    onChange={handleBladeWidthAndToothPitchChange}
                                    defaultValue={null}
                                    placeholder="Select Blade Width & Tooth Pitch"
                                />
                            </Col>
                            <Col xs={12} md={12} className="mb-2">
                                <Select
                                    name="feet"
                                    options={feetOptions}
                                    className="basic-multi-select flex-grow-1"
                                    menuPlacement="auto"
                                    classNamePrefix="Select2"
                                    onChange={handleFeetChange}
                                    defaultValue={feetOptions[0]}
                                />
                            </Col>
                            <Col xs={12} md={12} className="mb-2">
                                <Select
                                    name="inches"
                                    options={inchesOptions}
                                    className="basic-multi-select flex-grow-1"
                                    menuPlacement="auto"
                                    classNamePrefix="Select2"
                                    onChange={handleInchesChange}
                                    defaultValue={inchesOptions[0]}
                                />
                            </Col>
                            <Col xs={12} md={12} className="mb-2">
                                <Select
                                    name="fraction"
                                    options={fractionOptions}
                                    className="basic-multi-select flex-grow-1"
                                    menuPlacement="auto"
                                    classNamePrefix="Select2"
                                    onChange={handleFractionChange}
                                    defaultValue={fractionOptions[0]}
                                />
                            </Col>
                        </Row>
                    </Form>
                    <span className='switch-txt'><span className="switch-icon">&#x21bb;</span> Switch to Inches </span>
                </div>
            </div>
        </div>
    );
};

export default SelectSize4;
