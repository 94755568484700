import React from 'react';
import { Form, Row, Col, InputGroup } from 'react-bootstrap';
import Select, { StylesConfig } from 'react-select';

const BladeLength3: React.FC = () => {

    const Selectdata1 = [
        { value: 'Choose...', label: 'Choose...' },
        { value: 'One', label: 'One' },
        { value: 'Two', label: 'Two' },
        { value: 'Three', label: 'Three' },
    ];

    // Custom styles for the Select component
    const customStyles: StylesConfig = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',  // Adjust height for smaller size
            fontSize: '14px',   // Adjust font size
        }),
        container: (provided) => ({
            ...provided,
            width: '100%',  // Ensure the Select component takes the full width of its container
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: '4px',  // Adjust padding
        }),
        clearIndicator: (provided) => ({
            ...provided,
            padding: '4px',  // Adjust padding
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0px 6px',  // Adjust padding for smaller size
        }),
        input: (provided) => ({
            ...provided,
            margin: '0px',  // Adjust margin for better alignment
        }),
    };

    return (
        <div className="select-size">
            <div className='cart-card'>
                <div className='cart-card-body cart-card-body5'>
                    <Form>
                        <div className='bladelength-title-group'>
                            <span className="fs-18 mb-0 blade-length-title blade-length-main-title blade-length-main-title2">Blade Length Wizard™</span>
                            <span className='blade-length-title blade-length-title2'>Measure Twice Because We Cut Once!™</span>
                        </div>
                        <p className="blade-wizard-paragraph blade-wizard-paragraph2">
                            Use the Blade Length Wizard™ to double-check that you are ordering the correct length for your saw.
                            The number 1 reason for returns or exchanges is ordering the wrong length.
                        </p>
                        <Row>
                            <Col xs={12} md={4} className='d-flex align-items-center blade-input'>
                                <InputGroup className="m-0 w-100">  {/* Ensure full width for InputGroup */}
                                    <Select
                                        name="bladeLength1"
                                        options={Selectdata1}
                                        styles={customStyles} // Apply custom styles
                                        menuPlacement='auto'
                                        classNamePrefix="Select2"
                                        defaultValue={Selectdata1[0]}
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs={12} md={4} className='d-flex align-items-center blade-input'>
                                <InputGroup className="m-0 w-100">  {/* Ensure full width for InputGroup */}
                                    <Select
                                        name="bladeLength2"
                                        options={Selectdata1}
                                        styles={customStyles} // Apply custom styles
                                        menuPlacement='auto'
                                        classNamePrefix="Select2"
                                        defaultValue={Selectdata1[0]}
                                    />
                                </InputGroup>
                            </Col>
                            <Col xs={12} md={4} className='d-flex align-items-center blade-input'>
                                <p className='m-0 flex blade-rec-txt'>Blade Size Recommendation</p>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default BladeLength3;
