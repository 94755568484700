import React  from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import Select, { SingleValue } from 'react-select';

type OptionType = {
    value: string;
    label: string;
};

const SelectSize3: React.FC = () => {
    const feetOptions: OptionType[] = [
        { value: '', label: 'Select Feet' },
        { value: '1', label: '1 ft' },
        { value: '2', label: '2 ft' },
        { value: '3', label: '3 ft' },
    ];

    const inchesOptions: OptionType[] = [
        { value: '', label: 'Select Inches' },
        { value: '1', label: '1 in' },
        { value: '2', label: '2 in' },
        { value: '3', label: '3 in' },
    ];

    const fractionOptions: OptionType[] = [
        { value: '', label: 'Fraction/In' },
        { value: '1/4', label: '1/4' },
        { value: '1/2', label: '1/2' },
        { value: '3/4', label: '3/4' },
    ];

    const bladeWidthOptions: OptionType[] = [
        { value: '1/4"', label: 'Select Blade Width' },
        { value: '3/8"', label: '3/8"' },
        { value: '1/2"', label: '1/2"' },
        { value: '5/8"', label: '5/8"' },
        { value: '3/4"', label: '3/4"' },
    ];

    const toothpitchOptions: OptionType[] = [
        { value: '3', label: 'Select ToothPitch' },
        { value: '3 Hook', label: '3 Hook' },
        { value: '4 Hook', label: '4 Hook' },
        { value: '6', label: '6' },
        { value: '6 Hook', label: '6 Hook' },
        { value: '10', label: '10' },
        { value: '14', label: '14' },
        { value: '18', label: '18' },
        { value: '19', label: '19' },
    ];

    const handleFeetChange = (selectedOption: SingleValue<OptionType>) => {
        console.log('Selected Feet:', selectedOption ? selectedOption.value : '');
    };

    const handleInchesChange = (selectedOption: SingleValue<OptionType>) => {
        console.log('Selected Inches:', selectedOption ? selectedOption.value : '');
    };

    const handleFractionChange = (selectedOption: SingleValue<OptionType>) => {
        console.log('Selected Fraction:', selectedOption ? selectedOption.value : '');
    };

    const handleBladeWidthChange = (selectedOption: SingleValue<OptionType>) => {
        console.log('Selected Blade Width:', selectedOption ? selectedOption.value : '');
    };

    const handleToothpitchChange = (selectedOption: SingleValue<OptionType>) => {
        console.log('Selected ToothPitch:', selectedOption ? selectedOption.value : '');
    };

    return (
        <div className="select-size select-size-2">
            <div className='cart-card'>
                <div className='cart-card-body'>
                    <p className="fs-15 fw-semibold mb-3 small-title2">Select Blade</p>
                    <Form>
                        <Row>
                            <Col xs={12} md={12} className="mb-2">
                                <Select
                                    name="bladeWidth"
                                    options={bladeWidthOptions}
                                    className="basic-multi-select flex-grow-1"
                                    menuPlacement="auto"
                                    classNamePrefix="Select2"
                                    onChange={handleBladeWidthChange}
                                    defaultValue={bladeWidthOptions[0]}
                                />
                            </Col>
                            <Col xs={12} md={12} className="mb-2">
                                <Select
                                    name="toothpitch"
                                    options={toothpitchOptions}
                                    className="basic-multi-select flex-grow-1"
                                    menuPlacement="auto"
                                    classNamePrefix="Select2"
                                    onChange={handleToothpitchChange}
                                    defaultValue={toothpitchOptions[0]}
                                />
                            </Col>
                            <Col xs={12} md={12} className="mb-2">
                                <Select
                                    name="feet"
                                    options={feetOptions}
                                    className="basic-multi-select flex-grow-1"
                                    menuPlacement="auto"
                                    classNamePrefix="Select2"
                                    onChange={handleFeetChange}
                                    defaultValue={feetOptions[0]}
                                />
                            </Col>
                            <Col xs={12} md={12} className="mb-2">
                                <Select
                                    name="inches"
                                    options={inchesOptions}
                                    className="basic-multi-select flex-grow-1"
                                    menuPlacement="auto"
                                    classNamePrefix="Select2"
                                    onChange={handleInchesChange}
                                    defaultValue={inchesOptions[0]}
                                />
                            </Col>
                            <Col xs={12} md={12} className="mb-2">
                                <Select
                                    name="fraction"
                                    options={fractionOptions}
                                    className="basic-multi-select flex-grow-1"
                                    menuPlacement="auto"
                                    classNamePrefix="Select2"
                                    onChange={handleFractionChange}
                                    defaultValue={fractionOptions[0]}
                                />
                            </Col>
                        </Row>
                    </Form>
                    <span className='switch-txt'><span className="switch-icon">&#x21bb;</span> Switch to Inches </span>
                </div>
            </div>
        </div>
    );
};

export default SelectSize3;
