import React, { useState, useCallback, Suspense } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import Banner2 from '../Components/Banner2';
import NoticeBox2 from '../Components/NoticeBox2';
import Spacing from '../Components/Spacing';
import Loader from '../Components/loader';
import "../assets/css/front.css";
import ProductImage from '../Components/ProductImage';
import Quantity4 from '../Components/Quantity4';
import BladeLength3 from '../Components/BladeLength3';
import CustomTable7 from '../Components/CustomTable7';
import CustomTable6 from '../Components/CustomTable6';

import SelectSize5 from '../Components/SelectSize5';
import PricingOptions5 from '../Components/PricingOptions5';
import ProductReviews from '../Components/ProductReviews';
const ProductInfo = React.lazy(() => import('../Components/ProductInfo'));
const SpecificationTable = React.lazy(() => import('../Components/SpecificationTable'));
const Benefits = React.lazy(() => import('../Components/Benefits'));
const ApplicationSection = React.lazy(() => import('../Components/ApplicationSection'));
const DownloadableResources = React.lazy(() => import('../Components/DownloadableResources'));
const RelatedProducts2 = React.lazy(() => import('../Components/RelatedProducts2'));
const StickyFooter = React.lazy(() => import('../Components/StickyFooter'));
const Footer = React.lazy(() => import('../Components/Footer'));
const ProductDetail2 = React.lazy(() => import('../Components/ProductDetail2'));
const VideoPlayer = React.lazy(() => import('../Components/VideoPlayer'));

const Home7 = React.memo(() => {
  const [showTable2, setShowTable2] = useState(true);

  const handleNoticeClick = () => {
    setShowTable2((prevShowTable2) => !prevShowTable2);
  };

  const handleSelect = (eventKey: string | null) => {
    if (eventKey === 'blog') {
      window.location.href = 'https://bandsawblog.com/';
    }
  };

  const renderProductDetails = useCallback(() => (
    <Suspense fallback={<Loader />}>
      <ProductInfo />
    </Suspense>
  ), []);

  const renderSpecificationAndBenefits = useCallback(() => (
    <Row>
      <Col xs={12} md={6}>
        <Suspense fallback={<Loader />}>
          <SpecificationTable />
        </Suspense>
      </Col>
      <Col xs={12} md={6}>
        <Suspense fallback={<Loader />}>
          <Benefits />
          <ApplicationSection />
        </Suspense>
      </Col>
    </Row>
  ), []);

  return (
    <div>
     
      <Banner2 />

       
     
      <div className="sectionc1">
        <Container>
        <Spacing height={20} />
        <BladeLength3 />
          <Row>
            <Col xs={12} md={12}>
            {/* <Spacing height={5} /> */}
              <Row>
                <Col xs={12} md={12}>
                <Row>
                  <Col xs={12} md={8}>
                  <Suspense fallback={<Loader />}>
                <Spacing height={20} />
                {showTable2 ? <CustomTable6 /> : <CustomTable7 />}
                <Spacing height={10} />
                <NoticeBox2 onClick={handleNoticeClick} />
              </Suspense>
                  
                  </Col>
                  <Col xs={12} md={4}>
                  <Spacing height={20} />

                  <ProductImage />
    
                  
                  
                  </Col>
                </Row>
               
                
                </Col>
                <Col xs={12} md={12} className='zoom-out2'>
                {/* <ProductImage /> */}

                <Row>

                 

<Col xs={12} md={4} className="d-flex">
<Suspense fallback={<Loader />}>
  <SelectSize5 />
</Suspense>
</Col>
<Col xs={12} md={4} className="d-flex">
<Suspense fallback={<Loader />}>
  <PricingOptions5 />
</Suspense>
</Col>

<Col xs={12} md={4} className=' '>
<Suspense fallback={<Loader />}>
              
                <Quantity4 />
              </Suspense>
</Col>
</Row>
               

                </Col>

              </Row>
            
             

              <Spacing height={0} />

            </Col>

          </Row>
        </Container>
      </div>

      <div className='sectionc3'>
        <Container>
          <Suspense fallback={<Loader />}>
            <ProductDetail2 />
          </Suspense>

          <div className="description-container">
            <div className="cart-card2 my-4 cart-card-tab-data">
              <div className="cart-card-body">
                <Tab.Container defaultActiveKey="details" onSelect={handleSelect}>
                  <Nav variant="tabs" className="custom-tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="details">FEATURES & SPECS</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="specs">PRODUCT OVERVIEW</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="resources">DOWNLOADABLE RESOURCES</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="reviews">REVIEW</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="blog">BLOG</Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content className="mt-3">
                    <Tab.Pane eventKey="details">
                      {renderProductDetails()}
                      {renderSpecificationAndBenefits()}
                    </Tab.Pane>
                    <Tab.Pane eventKey="specs">
                      <Suspense fallback={<Loader />}>
                        <VideoPlayer />
                      </Suspense>
                    </Tab.Pane>
                    <Tab.Pane eventKey="resources">
                      <Suspense fallback={<Loader />}>
                        <DownloadableResources />
                      </Suspense>
                    </Tab.Pane>
                    <Tab.Pane eventKey="reviews">
                     <ProductReviews />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </Container>
      </div>
      
      <div className='sectionc4'>
        <Container>
          <Suspense fallback={<Loader />}>
            <Spacing height={20} />
            <RelatedProducts2 />
          </Suspense>
        </Container>
      </div>
      
      <Suspense fallback={<Loader />}>
        <StickyFooter />
        <Footer />
      </Suspense>
    </div>
  );
});

export default Home7;
