import React, { useCallback, Suspense } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import Banner2 from '../Components/Banner2';
import BladeLength2 from '../Components/BladeLength2';
import Spacing from '../Components/Spacing';
import Loader from '../Components/loader';
import "../assets/css/front.css";
import PricingOptions4 from '../Components/PricingOptions4';
import SelectSize4 from '../Components/SelectSize4';
import ProductReviews from '../Components/ProductReviews';

const Quantity3 = React.lazy(() => import('../Components/Quantity3'));
const ProductInfo = React.lazy(() => import('../Components/ProductInfo'));
const SpecificationTable = React.lazy(() => import('../Components/SpecificationTable'));
const Benefits = React.lazy(() => import('../Components/Benefits'));
const ApplicationSection = React.lazy(() => import('../Components/ApplicationSection'));
const DownloadableResources = React.lazy(() => import('../Components/DownloadableResources'));
const RelatedProducts2 = React.lazy(() => import('../Components/RelatedProducts2'));
const StickyFooter = React.lazy(() => import('../Components/StickyFooter'));
const Footer = React.lazy(() => import('../Components/Footer'));
const ProductDetail2 = React.lazy(() => import('../Components/ProductDetail2'));
const VideoPlayer = React.lazy(() => import('../Components/VideoPlayer'));

const Home2 = React.memo(() => {

  const handleSelect = (eventKey: string | null) => {
    if (eventKey === 'blog') {
      window.location.href = 'https://bandsawblog.com/';
    }
  };

  const renderProductDetails = useCallback(() => (
    <Suspense fallback={<Loader />}>
      <ProductInfo />
    </Suspense>
  ), []);

  const renderSpecificationAndBenefits = useCallback(() => (
    <Row>
      <Col xs={12} md={6}>
        <Suspense fallback={<Loader />}>
          <SpecificationTable />
        </Suspense>
      </Col>
      <Col xs={12} md={6}>
        <Suspense fallback={<Loader />}>
          <Benefits />
          <ApplicationSection />
        </Suspense>
      </Col>
    </Row>
  ), []);

  return (
    <div>
      <div className=''>
        <Banner2 />
        <div className="sectionc1">
          <Container>
            <Row>
              <Col xs={12} md={8}>
                <Spacing height={25} />
                <BladeLength2 />
                <Suspense fallback={<Loader />}>
                  <Spacing height={0} />
                </Suspense>
                <Spacing height={25} />

                <Row className="d-flex align-items-stretch">
                  <Col xs={12} md={6} className="d-flex">
                    <Suspense fallback={<Loader />}>
                      <SelectSize4 />
                    </Suspense>
                  </Col>
                  <Col xs={12} md={6} className="d-flex">
                    <Suspense fallback={<Loader />}>
                      <PricingOptions4 />
                    </Suspense>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={4} className="sticky-column zoom-out">
                <Suspense fallback={<Loader />}>
                  <Spacing height={30} />
                  <Quantity3 />
                </Suspense>
              </Col>
            </Row>
          </Container>
        </div>

        <div className='sectionc3'>
          <Container>
            <Suspense fallback={<Loader />}>
              <ProductDetail2 />
            </Suspense>

            <div className="description-container">
              <div className="cart-card2 my-4 cart-card-tab-data">
                <div className="cart-card-body">
                  <Tab.Container defaultActiveKey="details" onSelect={handleSelect}>
                    <Nav variant="tabs" className="custom-tabs">
                      <Nav.Item>
                        <Nav.Link eventKey="details">FEATURES & SPECIFICATION</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="specs">PRODUCT OVERVIEW</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="resources">DOWNLOADABLE RESOURCES</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="reviews">REVIEW</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="blog">BLOG</Nav.Link>
                      </Nav.Item>
                    </Nav>

                    <Tab.Content className="mt-3">
                      <Tab.Pane eventKey="details">
                        {renderProductDetails()}
                        {renderSpecificationAndBenefits()}
                      </Tab.Pane>
                      <Tab.Pane eventKey="specs">
                        <Suspense fallback={<Loader />}>
                          <VideoPlayer />
                        </Suspense>
                      </Tab.Pane>
                      <Tab.Pane eventKey="resources">
                        <Suspense fallback={<Loader />}>
                          <DownloadableResources />
                        </Suspense>
                      </Tab.Pane>
                      <Tab.Pane eventKey="reviews">
                      <ProductReviews />
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </Container>
        </div>
        
        <div className='sectionc4'>
          <Container>
            <Suspense fallback={<Loader />}>
              <Spacing height={20} />
              <RelatedProducts2 />
            </Suspense>
          </Container>
        </div>
      </div>

      <Suspense fallback={<Loader />}>
        <StickyFooter />
        <Footer />
      </Suspense>
    </div>
  );
});

export default Home2;
