import React from 'react';
import { Container, Col } from 'react-bootstrap';
import ProductImageSrc from '../assets/images/bandsawblade-1.jpg';

const ProductImage: React.FC = () => {
  return (
    <Container className="select-size custom-card product-card product-card2 card">
      <div className="cart-card">
        <div className="cart-card-body6">
          <Col>
            <img src={ProductImageSrc} alt="Product" className="img-fluid p-image p-image2" />
          </Col>
          <Col className="mt-3">
            <p className="fw-bold">Best For:</p>
            <ul className="best-for-list-inline">
              <li>Tool Steels</li>
              <li>Alloy Steels</li>
              <li>Titanium Alloys</li>
              <li>Bearing Steels</li>
              <li>Structural Steels</li>
              <li>Stainless Steels</li>
              <li>Mold Steels</li>
            </ul>
          </Col>
        </div>
      </div>
    </Container>
  );
};

export default ProductImage;
