import React, { useState } from 'react';
import { Col, Container, Modal } from 'react-bootstrap';
import Loader from './loader'; 


const VideoPlayer: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [videoUrl, setVideoUrl] = useState('');


  const handleVideoClick = (url: string) => {
    setVideoUrl(url);
    setIsModalOpen(true);
    setIsLoading(true);
  };


  const handleCloseModal = () => {
    setIsModalOpen(false);
    setVideoUrl('');
  };


  const handleIframeLoad = () => {
    setIsLoading(false);
  };


  return (
    <Container className="my-4">
      <div className="row">
        <Col md={9}>
          <div className="main-video">
            <div
              onClick={() => handleVideoClick("https://player.vimeo.com/video/800538133?h=6edbe8c0b9&title=0&byline=0&portrait=0")}
              style={{ cursor: 'pointer' }}
            >
              <img
                src="https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F759519312-adc45ae9863ec04d5d5846943d3d28cb0eccde0528aad2707d330f7af4799746-d_640&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                alt="Video thumbnail"
                style={{ width: '100%', height: 'auto' }} // Ensures responsiveness
              />
            </div>
            <div className="video-info">
              <p className='mb-1 p-0 video-title'>Q501 Series with Houston Metal Cutting</p>
              <p className='fs-14'>
                In this video, Daniel from Houston Metal Cutting discusses how the Q501 Bandsaw Blade is the perfect solution. The Q501 Band Saw Blade is made to cut all types of structural steel unique tooth profile preventing overfeeding and tooth strippage.
              </p>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div className="related-videos">
            <p className="fs-15 fw-semibold">Related Videos</p>
            <ul>
              {[1, 2, 3].map((index) => (
                <li key={index}>
                  <div
                    onClick={() => handleVideoClick("https://player.vimeo.com/video/800538133?h=6edbe8c0b9&title=0&byline=0&portrait=0")}
                    style={{ cursor: 'pointer' }}
                  >
                    <img
                      src="https://i.vimeocdn.com/filter/overlay?src0=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F758927415-d6d2b1a7475d14a31679d46a63b1c81d14baf1abf386fcc333331b05ade670f8-d_640&src1=http%3A%2F%2Ff.vimeocdn.com%2Fp%2Fimages%2Fcrawler_play.png"
                      alt="Related Video thumbnail"
                      style={{ width: '100%', height: 'auto' }} // Keeps original size
                    />
                  </div>
                  <p className='fs-14'>Q201 Hard Back Carbon</p>
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </div>


      <Modal show={isModalOpen} onHide={handleCloseModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="small-modal-title">Video Player</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ position: 'relative', minHeight: '400px' }}>
          {isLoading && (
            <div className="loader-container">
              <Loader />
            </div>
          )}
          <div
            style={{
              position: 'relative',
              paddingBottom: '56.25%', 
              height: 0,
              display: isLoading ? 'none' : 'block',
            }}
          >
            <iframe
              src={`${videoUrl}&autoplay=1`}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="Vimeo Video"
              onLoad={handleIframeLoad} 
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default VideoPlayer;
