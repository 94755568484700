import React from 'react';
import { Form, Row, Col, InputGroup } from 'react-bootstrap';
import Select from 'react-select';

const BladeLength2: React.FC = () => {


    //  
    const Selectdata1 = [
        { value: 'Choose...', label: 'Choose...' },
        { value: 'One', label: 'One' },
        { value: 'Two', label: 'Two' },
        { value: 'Three', label: 'Three' },
    ];


    return (
        <div className="select-size ">
            
          
           
        <div className='cart-card'>

            <div className='cart-card-body'>

            

       

            <Form className="">
            <div className=' bladelength-title-group'>

           
            <span className=" fs-18 mb-0 blade-length-title blade-length-main-title">Blade Length Wizard™</span>
          <span className='blade-length-title  '>Measure Twice Because We Cut Once!™</span>
          </div>
          <p className="blade-wizard-paragraph">
            Use the Blade Length Wizard™ to double-check that you are ordering the correct length for your saw.
            The number 1 reason for returns or exchanges is ordering the wrong length.
          </p>

          

          
                <Row>
                    <Col xs={12} md={4} className='d-flex align-items-center blade-input'>
                       
                        <InputGroup className="m-0">
                                        
                                        <Select name="colors" options={Selectdata1} className="basic-multi-select flex-grow-1"
                                            menuPlacement='auto' classNamePrefix="Select2" defaultValue={[Selectdata1[0]]}
                                        />
                                    </InputGroup>
                        
                    </Col>
                    <Col xs={12} md={4} className='d-flex align-items-center blade-input'>
                       
                        <InputGroup className="m-0">
                                        
                                        <Select name="colors" options={Selectdata1} className="basic-multi-select flex-grow-1"
                                            menuPlacement='auto' classNamePrefix="Select2" defaultValue={[Selectdata1[0]]}
                                        />
                                    </InputGroup>
                       
                    </Col>
                    <Col xs={12} md={4} className='d-flex align-items-center blade-input'>
                        <p className='m-0 flex'>Blade Size Recommendation</p>
                    </Col>
                </Row>
           

            </Form>
            </div>
            </div>
        </div>
    );
};

export default BladeLength2;
