import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { FaStar } from 'react-icons/fa';

interface ReviewComponentProps {
    username: string;
    date: string;
    reviewText: string;
}

const ReviewComponent: React.FC<ReviewComponentProps> = ({ username, date, reviewText }) => {
    return (
        <Card className="my-3 p-3 rounded shadow-sm">
            <Row className="align-items-start">
                <Col xs={2} md={1}>
                    <div className="d-flex justify-content-start align-items-center rounded-circle bg-light" style={{ width: '50px', height: '50px' }}>
                        <img
                            src="https://via.placeholder.com/50"
                            alt="User"
                            className="img-fluid rounded-circle"
                        />
                    </div>
                </Col>
                <Col xs={10} md={11}>
                    <div className="d-flex flex-column">
                        <div className="text-warning mb-2 ">
                            {[...Array(5)].map((_, i) => (
                                <FaStar key={i} />
                            ))}
                        </div>
                        <p className="fw-bold mb-1 d-flex">{username} <span className="text-muted mb-2">— {date}</span></p>
                        
                        <p className="mt-2 mb-0 text-secondary d-flex">{reviewText}</p>
                    </div>
                </Col>
            </Row>
        </Card>
    );
};

export default ReviewComponent;
