import React from 'react';
 
import { FaStar } from 'react-icons/fa'; 
import ReviewComponent from './ReviewComponent';
import ReviewForm from './ReviewForm';
import Spacing from './Spacing';

const ProductReviews: React.FC = () => {
  const fullStars = 4;  
  const partialStarPercentage = 20;  

  return (
    <>
      <div className="container">
       
        <div className="text-end mt-2 mt-md-0">
          <div className="rating d-flex align-items-center">
            <span className="me-2">4.2</span>
            {[...Array(fullStars)].map((_, index) => (
              <FaStar key={index} color="#ffc107" />
            ))}
            <div className="position-relative" style={{ display: 'inline-block', width: '16px', height: '16px' }}>
              <FaStar className="position-absolute" style={{ top: 0, left: 0 }} />
              <FaStar color="#ffc107" className="position-absolute" style={{ top: 0, left: 0, clipPath: `inset(0 ${100 - partialStarPercentage}% 0 0)` }} />
            </div>
            <span className="rating-text ms-2">Ratings (223)</span>
            <span className="ms-2">|</span>
            <span className="ms-2">Sold 99</span>
          </div>
        </div>
        <Spacing height={20} />

        <ReviewComponent
          username="Kishan Patel"
          date="April 23, 2022"
          reviewText="Sed perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
        />
        <ReviewComponent
          username="Michael Gillepsie"
          date="July 12, 2024"
          reviewText="Sed perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
        />
        <ReviewComponent
          username="Gazi Khan"
          date="January 23, 2024"
          reviewText="Sed perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
        />
      </div>

      <div>
        <ReviewForm />
      </div>
    </>
  );
};

export default ProductReviews;
