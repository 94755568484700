import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import Select, { SingleValue } from 'react-select';

type OptionType = {
    value: string;
    label: string;
};

const SelectSize5: React.FC = () => {
    const feetOptions: OptionType[] = [
        { value: '', label: 'Select Feet' },
        { value: '1', label: '1 ft' },
        { value: '2', label: '2 ft' },
        { value: '3', label: '3 ft' },
    ];

    const inchesOptions: OptionType[] = [
        { value: '', label: 'Select Inches' },
        { value: '1', label: '1 in' },
        { value: '2', label: '2 in' },
        { value: '3', label: '3 in' },
    ];

    const fractionOptions: OptionType[] = [
        { value: '', label: 'Fraction/In' },
        { value: '1/4', label: '1/4' },
        { value: '1/2', label: '1/2' },
        { value: '3/4', label: '3/4' },
    ];

    const [selectedFeet, setSelectedFeet] = useState<string>('');
    const [selectedInches, setSelectedInches] = useState<string>('');
    const [selectedFraction, setSelectedFraction] = useState<string>('');

    const handleFeetChange = (selectedOption: SingleValue<OptionType>) => {
        setSelectedFeet(selectedOption ? selectedOption.value : '');
    };

    const handleInchesChange = (selectedOption: SingleValue<OptionType>) => {
        setSelectedInches(selectedOption ? selectedOption.value : '');
    };

    const handleFractionChange = (selectedOption: SingleValue<OptionType>) => {
        setSelectedFraction(selectedOption ? selectedOption.value : '');
    };

    console.log(selectedFeet, selectedInches, selectedFraction);
    

    // const displayValue = () => {
    //     let result = 'Select Size';
    //     if (selectedFeet) result = `${selectedFeet} ft`;
    //     if (selectedInches) result += ` ${selectedInches} in`;
    //     if (selectedFraction) result += ` ${selectedFraction}`;
    //     return result;
    // };

    return (
        <div className="select-size select-size-2">
          
            <div className='cart-card'>
                <div className='cart-card-body cart-card-body5'>
                <p className="fs-15 fw-semibold mb-1 small-title">Select Blade </p>
               
                    <Form>
                        <Row>
                            <Col xs={12} md={12} className="mb-2">
                                <Select
                                    name="feet"
                                    options={feetOptions}
                                    className="basic-multi-select flex-grow-1"
                                    menuPlacement="auto"
                                    classNamePrefix="Select2"
                                    onChange={handleFeetChange}
                                    defaultValue={feetOptions[0]}
                                />
                            </Col>
                            <Col xs={12} md={12} className="mb-2">
                                <Select
                                    name="inches"
                                    options={inchesOptions}
                                    className="basic-multi-select flex-grow-1"
                                    menuPlacement="auto"
                                    classNamePrefix="Select2"
                                    onChange={handleInchesChange}
                                    defaultValue={inchesOptions[0]}
                                />
                            </Col>
                            <Col xs={12} md={12} className="mb-2">
                                <Select
                                    name="fraction"
                                    options={fractionOptions}
                                    className="basic-multi-select flex-grow-1"
                                    menuPlacement="auto"
                                    classNamePrefix="Select2"
                                    onChange={handleFractionChange}
                                    defaultValue={fractionOptions[0]}
                                />
                            </Col>
                            
                        </Row>
                    </Form>
                    {/* <p className="mt-3">{displayValue()}</p> */}
                    <span className='switch-txt'><span className="switch-icon">&#x21bb;</span> Switch to Inches </span>
                </div>
            </div>
        </div>
    );
};

export default SelectSize5;
