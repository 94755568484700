import React from 'react';
import { FaHandPointRight } from 'react-icons/fa';

const PricingOptions6: React.FC = () => {
    const pricingData = [
        { size: '1 Blade', price: '$19.35', isActive: true },
        { size: 'Order 2 to 4', price: '$19.12', isActive: false },
        { size: 'Order 2 to 4', price: '$19.12', isActive: false },
        { size: 'Order 2 to 4', price: '$19.12', isActive: false },
        { size: 'Order 2 to 4', price: '$19.12', isActive: false },
        { size: 'Order 2 to 4', price: '$19.12', isActive: false },
    ];

    return (
        <div className="cart-card">
            <div className="cart-card-body6">
                <div className="best-for-container mt-3">
                    <p className="pricing-title">Pricing Options</p>
                    <table className="pricing-table">
                        <tbody>
                            {pricingData.map((option, index) => (
                                <tr key={index} className={option.isActive ? 'active-pricing-option' : ''} style={{ position: 'relative' }}>
                                    <td className="pricing-size" style={{ paddingLeft: option.isActive ? '24px' : '0' }}>
                                        {option.isActive && (
                                            <span className="active-icon" style={{ position: 'absolute', left: '12px', top: '50%', transform: 'translateY(-50%)' }}>
                                                <FaHandPointRight />
                                            </span>
                                        )}
                                        {option.size}
                                    </td>
                                    <td className="pricing-price text-right">{option.price}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default PricingOptions6;
