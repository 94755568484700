import React, { useState, useCallback, Suspense } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import Banner2 from '../Components/Banner2';
import NoticeBox from '../Components/NoticeBox';
import BladeLength2 from '../Components/BladeLength2';
import Spacing from '../Components/Spacing';
import Loader from '../Components/loader';
import "../assets/css/front.css";
import ProductReviews from '../Components/ProductReviews';

const CustomTable2 = React.lazy(() => import('../Components/CustomTable2'));
const CustomTable3 = React.lazy(() => import('../Components/CustomTable3'));
const SelectSize2 = React.lazy(() => import('../Components/SelectSize2'));
const PricingOptions3 = React.lazy(() => import('../Components/PricingOptions3'));
const Quantity3 = React.lazy(() => import('../Components/Quantity3'));
const ProductInfo = React.lazy(() => import('../Components/ProductInfo'));
const SpecificationTable = React.lazy(() => import('../Components/SpecificationTable'));
const Benefits = React.lazy(() => import('../Components/Benefits'));
const ApplicationSection = React.lazy(() => import('../Components/ApplicationSection'));
const DownloadableResources = React.lazy(() => import('../Components/DownloadableResources'));
const RelatedProducts2 = React.lazy(() => import('../Components/RelatedProducts2'));
const StickyFooter = React.lazy(() => import('../Components/StickyFooter'));
const Footer = React.lazy(() => import('../Components/Footer'));
const ProductDetail2 = React.lazy(() => import('../Components/ProductDetail2'));
const VideoPlayer = React.lazy(() => import('../Components/VideoPlayer'));

const Home = React.memo(() => {
  const [showTable2, setShowTable2] = useState(true); // State to manage which table to show

  const handleNoticeClick = () => {
    setShowTable2((prevShowTable2) => !prevShowTable2); // Toggle between CustomTable2 and CustomTable3
  };

  const handleSelect = (eventKey: string | null) => {
    if (eventKey === 'blog') {
      window.location.href = 'https://bandsawblog.com/';
    }
  };

  const renderProductDetails = useCallback(() => (
    <Suspense fallback={<Loader />}>
      <ProductInfo />
    </Suspense>
  ), []);

  const renderSpecificationAndBenefits = useCallback(() => (
    <Row>
      <Col xs={12} md={6}>
        <Suspense fallback={<Loader />}>
          <SpecificationTable />
        </Suspense>
      </Col>
      <Col xs={12} md={6}>
        <Suspense fallback={<Loader />}>
          <Benefits />
          <ApplicationSection />
        </Suspense>
      </Col>
    </Row>
  ), []);

  return (
    <div>
      <Banner2 />
      <div className="sectionc1">
        <Container>
          <Row>
            <Col xs={12} md={8}>
              <Spacing height={30} />
              <BladeLength2 />
              <Suspense fallback={<Loader />}>
                <Spacing height={0} />
                {showTable2 ? <CustomTable2 /> : <CustomTable3 />}
                <NoticeBox onClick={handleNoticeClick} />
              </Suspense>
              <Spacing height={0} />

              <Row className="d-flex align-items-stretch">
                <Col xs={12} md={6} className="d-flex">
                  <Suspense fallback={<Loader />}>
                    <SelectSize2 />
                  </Suspense>
                </Col>
                <Col xs={12} md={6} className="d-flex">
                  <Suspense fallback={<Loader />}>
                    <PricingOptions3 />
                  </Suspense>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={4} className="sticky-column zoom-out">
              <Suspense fallback={<Loader />}>
                <Spacing height={30} />
                <Quantity3 />
              </Suspense>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='sectionc3'>
        <Container>
          <Suspense fallback={<Loader />}>
            <ProductDetail2 />
          </Suspense>

          <div className="description-container">
            <div className="cart-card2 my-4 cart-card-tab-data">
              <div className="cart-card-body">
                <Tab.Container defaultActiveKey="details" onSelect={handleSelect}>
                  <Nav variant="tabs" className="custom-tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="details">FEATURES & SPECS</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="specs">PRODUCT OVERVIEW</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="resources">DOWNLOADABLE RESOURCES</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="reviews">REVIEW</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="blog">BLOG</Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content className="mt-3">
                    <Tab.Pane eventKey="details">
                      {renderProductDetails()}
                      {renderSpecificationAndBenefits()}
                    </Tab.Pane>
                    <Tab.Pane eventKey="specs">
                      <Suspense fallback={<Loader />}>
                        <VideoPlayer />
                      </Suspense>
                    </Tab.Pane>
                    <Tab.Pane eventKey="resources">
                      <Suspense fallback={<Loader />}>
                        <DownloadableResources />
                      </Suspense>
                    </Tab.Pane>
                    <Tab.Pane eventKey="reviews">
                    <ProductReviews />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </Container>
      </div>
      
      <div className='sectionc4'>
        <Container>
          <Suspense fallback={<Loader />}>
            <Spacing height={20} />
            <RelatedProducts2 />
          </Suspense>
        </Container>
      </div>
      
      <Suspense fallback={<Loader />}>
        <StickyFooter />
        <Footer />
      </Suspense>
    </div>
  );
});

export default Home;
