import React, { useState } from 'react';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import { FaStar } from 'react-icons/fa';
import Spacing from './Spacing';

const ReviewForm: React.FC = () => {
    const [rating, setRating] = useState<number | null>(null);
    const [hover, setHover] = useState<number | null>(null);

    return (
        <Container>
                <Spacing height={40} />
        <p className='review-txt fs-15 fw-semibold'>Add a Review</p>
        <div className="divider"></div>

        <Spacing height={20} />

        <Form>
            <Form.Group controlId="rating" className='d-flex'>
                <Form.Label className='rating-label'>Your rating *</Form.Label>
                <Row className='stars-row'>
                    <Col>
                        <div className="d-flex">
                            {[...Array(5)].map((_, i) => {
                                const ratingValue = i + 1;
                                return (
                                    <label key={i}>
                                        <input
                                            type="radio"
                                            name="rating"
                                            value={ratingValue}
                                            style={{ display: 'none' }}
                                            onClick={() => setRating(ratingValue)}
                                        />
                                        <FaStar
                                            className="star"
                                            color={ratingValue <= (hover ?? rating ?? 0) ? "#ffc107" : "#e4e5e9"}
                                            size={24}
                                            onMouseEnter={() => setHover(ratingValue)}
                                            onMouseLeave={() => setHover(null)}
                                        />
                                    </label>
                                );
                            })}
                        </div>
                    </Col>
                </Row>
            </Form.Group>

            <Form.Group controlId="reviewText" className="mt-3">
                <Form.Label>Your review *</Form.Label>
                <Form.Control as="textarea" rows={5} />
            </Form.Group>

            <Form.Group controlId="name" className="mt-3">
                <Form.Label>Name *</Form.Label>
                <Form.Control type="text" placeholder="Enter your name" />
            </Form.Group>

            <Form.Group controlId="email" className="mt-3">
                <Form.Label>Email *</Form.Label>
                <Form.Control type="email" placeholder="Enter your email" />
            </Form.Group>

            <Form.Group controlId="saveInfo" className="mt-3">
                <Form.Check 
                    type="checkbox" 
                    label="Save my name, email, and website in this browser for the next time I comment." 
                />
            </Form.Group>

            <Button  variant='danger' type="submit" className="mt-3 btn-danger  w-auto">
                Submit Review
            </Button>
        </Form>
        </Container>
    );
};

export default ReviewForm;
