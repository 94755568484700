import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import Select, { StylesConfig, SingleValue, MultiValue, ActionMeta } from 'react-select';

interface TableRow {
  width: string;
  thickness: string;
  pitches: boolean[];
}

const initialTableData: TableRow[] = [
  { width: '1"', thickness: '.035', pitches: [false, false, false] },
  { width: '1-1/4"', thickness: '.025', pitches: [false, false, false] },
  { width: '1-1/2"', thickness: '.035', pitches: [false, false, false] },
  { width: '1-1/2"', thickness: '.025', pitches: [false, false, false] },
  { width: '2"', thickness: '.035', pitches: [false, false, false] },
];

const CustomTable4: React.FC = () => {
  const [highlightedCell, setHighlightedCell] = useState<{ rowIndex: number, colIndex: number } | null>(null);
  const [selectedCell, setSelectedCell] = useState<{ rowIndex: number, colIndex: number } | null>(null);
  const [tableData, setTableData] = useState<TableRow[]>(initialTableData);
  const [selectedWidth, setSelectedWidth] = useState<string | null>(null);
  const [selectedThickness, setSelectedThickness] = useState<string | null>(null);
  const [selectedPitch, setSelectedPitch] = useState<string | null>(null);

  const handleCellClick = (rowIndex: number, colIndex: number) => {
    const newTableData = tableData.map((row, rIdx) => ({
      ...row,
      pitches: row.pitches.map((_, cIdx) => rIdx === rowIndex && cIdx === colIndex)
    }));
    setTableData(newTableData);
    setSelectedCell({ rowIndex, colIndex });
  };

  const handleMouseEnter = (rowIndex: number, colIndex: number) => {
    setHighlightedCell({ rowIndex, colIndex });
  };

  const handleMouseLeave = () => {
    setHighlightedCell(null);
  };

  const handleWidthChange = (selected: SingleValue<{ label: string, value: string }> | MultiValue<{ label: string, value: string }>, _actionMeta: ActionMeta<{ label: string, value: string }>) => {
    const selectedOption = selected as SingleValue<{ label: string, value: string }>;
    setSelectedWidth(selectedOption?.value || null);
  };

  const handleThicknessChange = (selected: SingleValue<{ label: string, value: string }> | MultiValue<{ label: string, value: string }>, _actionMeta: ActionMeta<{ label: string, value: string }>) => {
    const selectedOption = selected as SingleValue<{ label: string, value: string }>;
    setSelectedThickness(selectedOption?.value || null);
  };

  const handlePitchChange = (selected: SingleValue<{ label: string, value: string }> | MultiValue<{ label: string, value: string }>, _actionMeta: ActionMeta<{ label: string, value: string }>) => {
    const selectedOption = selected as SingleValue<{ label: string, value: string }>;
    setSelectedPitch(selectedOption?.value || null);
    if (selectedOption) {
      const [rowIndex, colIndex] = selectedOption.value.split('-').map(Number);
      handleCellClick(rowIndex, colIndex);
    }
  };

  const widthOptions = [...new Set(tableData.map(row => row.width))].map(width => ({
    label: width,
    value: width
  }));

  const thicknessOptions = [...new Set(tableData.map(row => row.thickness))].map(thickness => ({
    label: thickness,
    value: thickness
  }));

  const pitchOptions = tableData.flatMap((row, rowIndex) =>
    row.pitches.map((_, colIndex) => ({
      label: `Width: ${row.width}, Thick: ${row.thickness}, Pitch: ${colIndex + 1}`,
      value: `${rowIndex}-${colIndex}`
    }))
  );

  const customStyles: StylesConfig<{ label: string, value: string }> = {
    control: (base: any) => ({
      ...base,
      borderColor: '#ccc',
      '&:hover': { borderColor: '#DA002A' },
      boxShadow: 'none'
    }),
    singleValue: (base: any) => ({
      ...base,
      color: '#333'
    }),
    option: (base: any, { isFocused, isSelected }: any) => ({
      ...base,
      backgroundColor: isSelected ? '#DA002A' : isFocused ? '#DA002A' : undefined,
      color: isSelected || isFocused ? 'white' : 'black',
      '&:hover': {
        backgroundColor: 'rgba(218, 0, 42, 0.15)',
        color: isSelected || isFocused ? 'black' : 'black',
      },
      '&:active': {
        backgroundColor: 'rgba(218, 0, 42, 0.15)',
        color: isSelected || isFocused ? 'black' : 'white',
      },
    }),
    menu: (base: any) => ({
      ...base,
      zIndex: 9999 
    })
  };

  return (
    <div className='mt-4 select-dimensions'>
      <p className="fs-15 fw-semibold mb-1 small-title">Select Dimensions</p>

      <div className="table-container cart-card desktop-view">
        <Table bordered className="custom-table custom-table4 text-center cart-card-body">
          <thead>
            <tr className='table-head'>
              <th>Width</th>
              <th>Thick</th>
              <th colSpan={14}>Tooth Pitch</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th>2</th>
              <th>3/4</th>
              <th>7/8</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr 
                key={rowIndex}
                onMouseEnter={() => handleMouseEnter(rowIndex, -1)} 
                onMouseLeave={handleMouseLeave}
              >
                <td className={(highlightedCell && highlightedCell.rowIndex === rowIndex) || (selectedCell && selectedCell.rowIndex === rowIndex) ? 'highlight' : ''}>
                  {row.width}
                </td>
                <td className={(highlightedCell && highlightedCell.rowIndex === rowIndex) || (selectedCell && selectedCell.rowIndex === rowIndex) ? 'highlight' : ''}>
                  {row.thickness}
                </td>
                {row.pitches.map((pitch, colIndex) => (
                  <td 
                    key={colIndex} 
                    className={((highlightedCell && (highlightedCell.rowIndex === rowIndex || highlightedCell.colIndex === colIndex)) || (selectedCell && (selectedCell.rowIndex === rowIndex || selectedCell.colIndex === colIndex))) ? 'highlight' : ''} 
                    onClick={() => handleCellClick(rowIndex, colIndex)}
                    onMouseEnter={() => handleMouseEnter(rowIndex, colIndex)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <input type="radio" checked={pitch} readOnly />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div className="mobile-view">
        <Select 
          className="custom-select"
          value={widthOptions.find(option => option.value === selectedWidth) || null}
          onChange={handleWidthChange}
          options={widthOptions}
          placeholder="Select Width"
          styles={customStyles}
        />
        <Select 
          className="custom-select"
          value={thicknessOptions.find(option => option.value === selectedThickness) || null}
          onChange={handleThicknessChange}
          options={thicknessOptions}
          placeholder="Select Thick"
          styles={customStyles}
        />
        <Select 
          className="custom-select"
          value={pitchOptions.find(option => option.value === selectedPitch) || null}
          onChange={handlePitchChange}
          options={pitchOptions}
          placeholder="Select Pitch"
          styles={customStyles}
        />
      </div>
    </div>
  );
};

export default CustomTable4;
