import React from 'react';

const PricingOptions5: React.FC = () => {
    const pricingData = [
        { size: '1 Blade', price: '$19.35', isActive: true },
        { size: 'Order 2 to 4', price: '$19.12', isActive: false },
        { size: 'Order 2 to 4', price: '$19.12', isActive: false },
        { size: 'Order 2 to 4', price: '$19.12', isActive: false },
        { size: 'Order 2 to 4', price: '$19.12', isActive: false },
        { size: 'Order 2 to 4', price: '$19.12', isActive: false },
    ];

    return (
        <div className="select-size pricing-options">
            
             
            <div className='cart-card'>
            
                <div className='cart-card-body cart-card-body5 pricing-options-body'>
                <p className="fs-15 fw-semibold mb-1 small-title">Order in Bulk and Save</p>
               

           
          
            {pricingData.map((option, index) => (
                <span
                    key={index}
                    className={`fs-12 badge m-1 p-2 ${option.isActive ? 'option-active' : 'option-inactive'}`}
                >
                    {option.size} - {option.price}
                </span>
            ))}
            </div>
             </div>
        </div>
    );
};

export default PricingOptions5;
