import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import './index.scss';

import Header from './Components/Header';
import Home from './Screens/Home';

import VideoPlayer from './Components/VideoPlayer';
import Home2 from './Screens/Home2';
import Home3 from './Screens/Home3';
import Home4 from './Screens/Home4';
import Home5 from './Screens/Home5';
import Home6 from './Screens/Home6';
import Home7 from './Screens/Home7';
import Home8 from './Screens/Home8';
import Home9 from './Screens/Home9';


function App() {
  return (
    <Router>
      <div className="App">
        <Header /> 
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/videos" element={<VideoPlayer />} />
          <Route path="/home2" element={<Home2 />} />
          <Route path="/home3" element={<Home3 />} />
          <Route path="/home4" element={<Home4 />} />
          <Route path="/home5" element={<Home5 />} />
          <Route path="/home6" element={<Home6 />} />
          <Route path="/home7" element={<Home7 />} />
          <Route path="/home8" element={<Home8 />} />
          <Route path="/home9" element={<Home9 />} />
 

        </Routes>
      </div>
    </Router>
  );
}

export default App;
