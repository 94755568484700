import React, { useState, useCallback, Suspense } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import Banner2 from '../Components/Banner2';
import Spacing from '../Components/Spacing';
import Loader from '../Components/loader';
import ProductReviews from '../Components/ProductReviews';
import ProductImage2 from '../Components/ProductImage2';
import Quantity6 from '../Components/Quantity6';
import PricingOptions6 from '../Components/PricingOptions6';
import BestForList from '../Components/BestForList';
import img1 from '../assets/images/img1.jpg';
import img2 from '../assets/images/img2.jpg';
import img3 from '../assets/images/img3.jpg';
import img4 from '../assets/images/img4.jpg';

const ProductInfo = React.lazy(() => import('../Components/ProductInfo'));
const SpecificationTable = React.lazy(() => import('../Components/SpecificationTable'));
const Benefits = React.lazy(() => import('../Components/Benefits'));
const ApplicationSection = React.lazy(() => import('../Components/ApplicationSection'));
const DownloadableResources = React.lazy(() => import('../Components/DownloadableResources'));
const RelatedProducts2 = React.lazy(() => import('../Components/RelatedProducts2'));
const StickyFooter = React.lazy(() => import('../Components/StickyFooter'));
const Footer = React.lazy(() => import('../Components/Footer'));
const ProductDetail3 = React.lazy(() => import('../Components/ProductDetail3'));
const VideoPlayer = React.lazy(() => import('../Components/VideoPlayer'));

const Home9 = React.memo(() => {

  const [selectedImage, setSelectedImage] = useState(img1); // State for selected image


  const handleSelectImage = () => {
    const images = [img1, img2, img3, img4];
    const randomImage = images[Math.floor(Math.random() * images.length)];
    setSelectedImage(randomImage);
  };

  const handleSelect = (eventKey: string | null) => {
    if (eventKey === 'blog') {
      window.location.href = 'https://bandsawblog.com/';
    }
  };

  const renderProductDetails = useCallback(() => (
    <Suspense fallback={<Loader />}>
      <ProductInfo />
    </Suspense>
  ), []);

  const renderSpecificationAndBenefits = useCallback(() => (
    <Row>
      <Col xs={12} md={6}>
        <Suspense fallback={<Loader />}>
          <SpecificationTable />
        </Suspense>
      </Col>
      <Col xs={12} md={6}>
        <Suspense fallback={<Loader />}>
          <Benefits />
          <ApplicationSection />
        </Suspense>
      </Col>
    </Row>
  ), []);

  return (
    <div>
      <Banner2 />
      <div className="sectionc1">
        <Container>
        <Suspense fallback={<Loader />}>
          <ProductDetail3
            onBladeWidthChange={handleSelectImage}
            onSelectionChange={handleSelectImage} 
          />
        </Suspense>
        <Spacing height={8} />
        <Row className="flex-container">
          <Col md={3} className="d-flex p-2">
            <ProductImage2 selectedImage={selectedImage} />
          </Col>
          <Col md={2} className="d-flex p-2">
            <BestForList />
          </Col>
          <Col md={4} className="d-flex p-2">
            <Quantity6 />
          </Col>
          <Col md={3} className="d-flex p-2">
            <PricingOptions6 />
          </Col>
        </Row>
        <Row>
          <Col>
            <Suspense fallback={<Loader />}>
              {/* <CustomTable8 onSelectionChange={handleSelectImage} /> */}
            </Suspense>
          </Col>
        </Row>
        </Container>
      </div>
      <div className='sectionc3'>
        <Container>
          <div className="description-container">
            <div className="cart-card2 my-4 cart-card-tab-data">
              <div className="cart-card-body">
                <Tab.Container defaultActiveKey="details" onSelect={handleSelect}>
                  <Nav variant="tabs" className="custom-tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="details">FEATURES & SPECS</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="specs">PRODUCT OVERVIEW</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="resources">DOWNLOADABLE RESOURCES</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="reviews">REVIEW</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="blog">BLOG</Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content className="mt-3">
                    <Tab.Pane eventKey="details">
                      {renderProductDetails()}
                      {renderSpecificationAndBenefits()}
                    </Tab.Pane>
                    <Tab.Pane eventKey="specs">
                      <Suspense fallback={<Loader />}>
                        <VideoPlayer />
                      </Suspense>
                    </Tab.Pane>
                    <Tab.Pane eventKey="resources">
                      <Suspense fallback={<Loader />}>
                        <DownloadableResources />
                      </Suspense>
                    </Tab.Pane>
                    <Tab.Pane eventKey="reviews">
                      <ProductReviews />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className='sectionc4'>
        <Container>
          <Suspense fallback={<Loader />}>
            <Spacing height={20} />
            <RelatedProducts2 />
          </Suspense>
        </Container>
      </div>
      <Suspense fallback={<Loader />}>
        <StickyFooter />
        <Footer />
      </Suspense>
    </div>
  );
});

export default Home9;
