import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';  

const BestForList: React.FC = () => {
  return (
    <div className="cart-card">
      <div className="cart-card-body6">
        <div className="best-for-container mt-3">
          <p className="best-for-title">Best For</p>
          <ul className="best-for-list">
            <li><FaCheckCircle className="icon" /> Tool Steels</li>
            <li><FaCheckCircle className="icon" /> Alloy Steels</li>
            <li><FaCheckCircle className="icon" /> Titanium Alloys</li>
            <li><FaCheckCircle className="icon" /> Bearing Steels</li>
            <li><FaCheckCircle className="icon" /> Structural Steels</li>
            <li><FaCheckCircle className="icon" /> Stainless Steels</li>
            <li><FaCheckCircle className="icon" /> Mold Steels</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BestForList;
