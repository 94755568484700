import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaPlus, FaMinus, FaShippingFast } from 'react-icons/fa';  // Importing the shipping icon

import Spacing from './Spacing';

const Quantity4: React.FC = () => {
  const [quantity, setQuantity] = useState(1);

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  return (
    <div className="select-size custom-card">
      <div className='cart-card'>
        <div className='cart-card-body'>
          <Container>

            <Row className="align-items-center mt-3">
              <Col>
                <div className="price-section">
                  <span className="current-price">$19.35</span>
                </div>
              </Col>
              <Col className='counter'>
                <Row>
                  <Col xs="4">
                    <Button variant='light' className="btn-wave fs-18 equal-width-btn btn-grey-color" onClick={decreaseQuantity}>
                      <FaMinus /> {/* Minus icon */}
                    </Button>
                  </Col>
                  <Col xs="4" className='quantity-label'>
                    <span style={{ fontSize: '18px' }}>{quantity}</span>
                  </Col>
                  <Col xs="4">
                    <Button variant='light' className="btn-wave fs-18 equal-width-btn btn-grey-color" onClick={increaseQuantity}>
                      <FaPlus /> {/* Plus icon */}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="align-items-center mt-3 quantity-row">
              <Col className='quantity-txt'>
                <Button variant='danger' className="btn-add-to-cart">
                  Add to Cart
                </Button>
              </Col>
            </Row>

            <div className='divider'></div>

            <Spacing height={10} />

            <Row className="align-items-center">
              <Col className='text-left d-flex align-items-center'>
                <FaShippingFast style={{ marginRight: '30px', fontSize: '34px' }} /> {/* Adjusted size for the shipping icon */}
                <div>
                  <div>
                    <span className='shipping-title'>Estimated to Ship 11/20 - 11/22</span>
                  </div>
                  <div>
                    <span className='shipping-subtitle'>Ship It Free (Lower 48 States) See Shipping Options</span>
                  </div>
                </div>
              </Col>
            </Row>
            
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Quantity4;
