import React from 'react';
import { Container } from 'react-bootstrap';

interface SpacingProps {
  height?: number;
  width?: number;
}

const Spacing: React.FC<SpacingProps> = ({ height, width }) => {
  return (
    <Container
      fluid
      style={{
        height: height ? `${height}px` : 'auto',
        width: width ? `${width}px` : '100%',
      }}
    />
  );
};

export default Spacing;
