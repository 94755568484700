import React from 'react';
import { Container, Col } from 'react-bootstrap';


const ProductImage2: React.FC<{ selectedImage: string }> = ({ selectedImage }) => {
  return (
    <Container className="select-size custom-card product-card product-card2 card">
      <div className="cart-card">
        <div className="cart-card-body6">
          <Col>
            <img src={selectedImage} alt="Product" className="img-fluid p-image p-image2" />
          </Col>
        </div>
      </div>
    </Container>
  );
};

export default ProductImage2;
