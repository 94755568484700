import React from 'react';
import { Alert } from 'react-bootstrap';

const NoticeBox2: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Alert variant="light" className="notice-box notice-box2" onClick={onClick} style={{ cursor: 'pointer' }}>
      <div className="notice-icon notice-icon2"></div>
      For 1/2" blades we recommend .025 for sizes 7'9 1/2" (93.5 Inches) and shorter.
    </Alert>
  );
};

export default NoticeBox2;
